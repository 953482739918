.option-button {
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: rgb(212 199 196 / 20%);
    text-align: center;
    color: #585858;
 &.active { background-color: #cb340f !important;
    color: white !important;}
      &:hover {
    background: #cb340f;
    color: white;
}

}
.option-inactive {
    width: 200px;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #a7a4a4;
    background: rgb(24 24 24 / 20%);
    text-align: center;
    color: #585858;
}
.change-buttons{
 margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: #cb340f;
    text-align: center;
    color: white !important;
  &.active { background-color: #cb340f !important;
    color: white !important;}
   &:hover {
    background: #cb340f;
    color: white;

}
}
.event-restart{
    padding-left: 25px !important;
}

     

