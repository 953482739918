.help-landing {
    padding: 0 20px;

    .on-boarding-header-help {
        text-align: center;
        color: #1D1C1D;
        font-size: 34px;
        margin-top: 62px;
        margin-bottom: 40px;
    }
    .event-icons {
        align-items: center;
    
        .event-icon {
            margin-right: 0; 
        }
    
        .event-icon + .event-icon {
            margin-left: -9px; 
        }
    }
  
    .landing-event-image-issue {
        background-image: url(../../image/new-issue.png);
        height: 200px;
        width: 59%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center !important;
        margin-left: 3%;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
        margin-bottom: 2%;

    }
    .landing-event-image-maintenance {
        background-image: url(../../image/Maintenance_2.png);
        height: 200px;
        width: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-left: 18%;
        margin-bottom: 2%;

    }
    .landing-event-image-announcements {
        background-image: url(../../image/Announcements.png);
        height: 200px;
        width: 50%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-left: 18%;
        margin-bottom: 2%;

    }
    .landing-event-available-issue{
        background-image: url(../../image/available-2-new.png);
        height: 200px;
        width: 59%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        margin-left: 3%;
        margin-bottom: 2%;
    }
    .event-steps {
        padding: 0 4px;
        margin-bottom: 32px;
        max-width: 100%; 
        margin-left: 15%;
        margin-right: auto; 

        .event-steps {

            .step-header-event {
                font-size: 16px;
                font-weight: bold;
                 margin-bottom: 10px;
            }
            .step-header-event-issue {
                font-size: 20px !important;
                font-weight: bold;
                 margin-bottom: 10px;
            }
           


            ul {
                font-size: 13px;
                color: #525252;
                padding-left: 17px;
                font-weight: 400;

                li {
                     margin-bottom: 4px;
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        content: '\25B2';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 10px;
                        height: 10px;
                        background-size: contain;
                    }
                }

                a {
                    color: #2680EB;
                    text-decoration: none;
                    letter-spacing: 0px;
                    opacity: 1;
                }
            }
        }
    }
    .event-header-new {
        font-size: 20px !important;
        // font-weight: bold;
        margin-bottom: 10px;
    }
}