.feedback-div{
background-color: #fff;
    border-radius: 3px;
    box-shadow: 5px 5px 13px rgba(91, 81, 81, .4);
    max-width: 350px;
    padding: 20px;
    position: absolute;
    right: 310px;
    top: 0;
    width: 100%;
    border: 1px solid #cb340f;
.initial-text{
    padding-top: 13px;
.form-submit{
    padding-bottom: 15px;}
}

}
.nodisplay{
    display: none;
}
.feedback-cross{
    border: 1px solid #9f9191;
    font-size: 13px;
    position: absolute;
    right: 2px;
    top: 2.5px;
}
.feedback-cross::after{
 content: "X";
  cursor: pointer;
  padding: 2px 4px 2px 4px;
 
  width: 10px;
  height: 10px;
}

.feedback-button-div{
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.feedback-button-yes{
  border-radius: 50%;
  cursor: pointer;
  background: none;
  padding: 5px;
  border: 1px solid #268e6c;
}
.yes-active{
    background-color: #268e6c;
    color: white;
}

.feedback-button-no{   
  border-radius: 50%;
  cursor: pointer;
  background: none;
  padding: 5px;
  border: 1px solid #d7373f;   
}
.no-active{
    background-color: #d7373f;
    color: white;
}
.feedback-button-yes:hover{
  background-color: #268e6c;
  color: white;
}
.feedback-button-no:hover{
  background-color: #d7373f;
  color: white;
}

.feedback-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}
.feedback-submit{
    background-color: #cb340f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.submit-disabled{
background: rgb(24 24 24 / 20%);
border: 1px solid #8a8585;
}
.hightlight{
    background-color: #f7f7f7;
}
.mandatory{
    color: red;
    float: right;
    margin-top: -35px;
    margin-right: 10px;
}
.charcount{
position: absolute;
    bottom: 65px;
    right: 21px;
    font-size: 12px;
    color: red;
    pointer-events: none;
}
