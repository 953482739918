$font-family: adobe-clean, Helvetica, Arial, sans-serif;
$text-primary-color: #707070;
$page-heading-font: 1.375rem;
$heading-font: 1.125rem;
$regular-font: 0.875rem;
$available-color: #2d9d78;
$major-incident-color: #d7373f;
$major-incident-label: #e34850;
$minor-incident-color: #e68619;
$discovery-incident-color: #a1a1a1;

//banner colors
$available-banner-color: #268e6c;
$major-banner-color: #d7373f;
$maintenance-banner-color: #2680eb;
$minor-banner-color: #e68619;
$discovery-banner-color: #808080;
$message-banner-color: #ffd300;

$maintenance-color: #2680eb;
$discovery-color: #808080;
$header-color: #4b4b4b;
$popover-color: #2c2c2c;
$popover-fade-color: #8e8e8e;

.chatbot-div {
  position: fixed;
  float: right;
  right: 16px;
  bottom: calc(15%);
  z-index: 7000;
}
.chatbot-icon {
  // position: fixed;

  // height: 1000px;
  &:hover {
    background-color: black;
  }
  &::after {
    cursor: pointer;
    content: "";
    position: fixed;
    // background-image: url("../../image/chatbot94.png");
    background-image: url("../../image/testimg8.png");
    width: 66px;
    height: 66px;
    display: inline-block;
    // position: absolute;
    right: 0 !important;
    // margin-bottom: 250px;
    margin-right: 50px !important;
    margin-top: 50px;
    // margin-top: -150px;
  }
}
.chatbot-icon.cross::after {
  cursor: pointer;
  background-image: url("../../image/img5.png");
  width: 60px;
  height: 60px;
}
// &::before {
//   background-image: url("../../image/subscribe.png");
// }
// }
.react-chatbot-kit-chat-container {
  position: relative;
  width: 310px !important;
  height: 520px;
}
// .react-chatbot-kit-chat-message-container {
//   height: 450px;
// }
.react-chatbot-kit-chat-btn-send {
  background-color: #d7373f !important;
}
input.react-chatbot-kit-chat-input:focus-visible {
  outline-color: #d7373f !important;
}
.react-chatbot-kit-chat-bot-message {
  background-color: rgb(212 199 196 / 20%) !important;
  color: #585858 !important;
  margin-left: 0 !important;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right: 8px solid #f1f1f1 !important;
}
.css-1rhbuit-multiValue{
 background:  rgb(212 199 196 / 20%) !important;
}
.react-chatbot-kit-chat-inner-container {
  box-shadow: 5px 10px 20px 2px #888888 !important;
}
.react-chatbot-kit-chat-header {
  background: linear-gradient(to right, #cb340f, #feb47b);
  background-color: #d7373f !important;
  color: white !important;
  height: 40px !important;
  text-align: center !important;
  padding: 20px !important;
}
.react-chatbot-kit-chat-input-container{
display:none !important;
}
.react-chatbot-kit-user-avatar-icon {
  display:none;
  fill: #746a6a !important;
}
// .react-chatbot-kit-chat-bot-avatar{
// display: none !important;
// }
.react-chatbot-kit-user-avatar-container {
  display: none !important;
//   background-color: #d8d8d8 !important;
}
.chatbot {
  text-align: center;
  // background-color: #282c34;
  // margin-left: 1700px;
  // min-height: 100vh;
  // display: flex;
  justify-content: center;
  align-items: center;
}

.error-info {
  background: #fff4f4;
  margin: 20px 0;
  padding: 12px;
  color: #b31e1e;
  border-radius: 2px;

  p {
    margin-bottom: 0;
  }
}

.overview-wrapper {
  margin-bottom: 200px;
}

.overview-tab {
  margin: 10px 0;
}

.service-dd__multi-value__label {
  .product-guide {
    display: none;
  }
}

.event-tabs {
  display: block;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: inline-block;
    cursor: pointer;
    color: #228ecc;

    &.active {
      color: #ddd;
    }

    &:first-child {
      &::after {
        content: "|";
        color: #228ecc;
        margin: 0 5px;
      }
    }
  }

  .my-events-icon {
    background-image: url("../../image/icons.svg");
    background-position: 4px -371px;
    display: inline-block;
    height: 17px;
    width: 19px;
    position: absolute;
    cursor: pointer;

    .my-events-icon-tooltip {
      visibility: hidden;
      background-color: white;
      width: 255px;
      color: #2c2c2c;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: -48px;
      left: 13px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
        rgba(0, 0, 0, 0.22) 0px 10px 10px;
    }
  }

  .my-events-icon:hover .my-events-icon-tooltip {
    visibility: visible;
  }
}

p.no-events {
  margin: 20px 0px 30px;
  line-height: 15px;
}

.cloud-message {
  display: inline-block;
  width: calc(100% - 30px);
  padding-left: 26px;
}

.mobile-tiles {
  padding-top: 10px;
}

.mobile-dropdown {
  margin-bottom: 10px;
}

ul.mobile-list {
  padding: 0px;

  .event-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      list-style: none;
      padding-bottom: 10px;

      .icon {
        margin-right: 12px;
        float: left;
        padding: 0;
        width: 32px;
        height: 32px;
      }

      .text {
        float: left;
        font-weight: bold;
        padding-top: 5px;
        font-size: 14px;
      }

      .cloud-name {
        display: block;

        cursor: pointer;
      }
    }
  }

  .collapsible .vertical-center::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    border-right: none;
    left: 5px;
    top: 0;
  }

  .status-mobile {
    float: right;
    font-size: 12px;
    display: block;
    margin-top: -25px;
    padding-right: 5px;
    clear: both;

    .icon-circle {
      margin-right: 3px;
      cursor: default !important;
    }
  }
}

ul.cloud-list {
  list-style: none;
  margin: 15px 0px 5px;
  padding: 10px;
  box-shadow: 0 0 1px #aaa;
  border-radius: 3px;

  li {
    width: 19%;
    float: left;

    .icon {
      margin-right: 12px;
      float: left;
      padding: 0;
      width: 32px;
      height: 32px;
    }

    .text {
      float: left;
      font-weight: bold;
      padding-top: 5px;
      font-size: 14px;
    }

    .cloud-name {
      display: block;
      margin-bottom: 5px;
      cursor: pointer;
      height: 36px;
    }

    .status {
      font-size: 12px;
      display: block;
      margin-top: 5px;
      padding-right: 5px;
      clear: both;
      position: relative;

      i.event-icon {
        width: 20px;
        height: 20px;
        top: 0;
        position: absolute;
        padding-left: 0;
      }

      .icon-circle {
        margin-right: 3px;
        cursor: default !important;
      }
    }

    &:last-child {
      width: 24%;

      .text {
        width: 165px;
        position: relative;
        top: -8px;
      }
    }
  }
}
.navigation-help {
  float: right;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      color: #228ecc;
      cursor: pointer;

      &.active {
        color: #ddd;
      }

      &:first-child {
        &::after {
          content: "|";
          color: #228ecc;
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}
.expand-collapse {
  float: right;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      color: #228ecc;
      cursor: pointer;

      &.active {
        color: #ddd;
      }

      &:first-child {
        &::after {
          content: "|";
          color: #228ecc;
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}

.line-seperator {
  color: #228ecc;
  right: 0;
  margin-right: 4px;
  margin-left: 5px;
}

@media (min-width: 1400px) {
  .overview-container {
    .container {
      width: 1200px;
    }
  }
}

@media (max-width: 1000px) {
  .overview-container {
    ul.cloud-list.clearfix {
      display: none;
    }

    .dropdown {
      margin-bottom: 10px;
    }
  }

  .container,
  .container-sm {
    max-width: 100% !important;
    width: 100%;
  }

  .overview-container {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    ul.mobile-list .event-list li {
      padding-bottom: 0px;
      margin: 10px 0;
    }

    ul.mobile-list .event-tabs {
      margin-top: 20px;
    }
  }
}

@media (max-width: 767px) {
  .container,
  .container-sm {
    max-width: 100% !important;
    width: 100%;
  }

  .overview-container {
    ul.mobile-list .event-list li {
      padding-bottom: 0px;
      margin: 10px 0;
    }

    ul.mobile-list .event-tabs {
      margin-top: 20px;
    }
  }
}

@media (max-width: 480px) {
  .overview-container {
    ul.mobile-list .status-mobile {
      float: left;
      margin-left: 45px;
      padding-top: 10px;
      padding-left: 0px;
    }

    ul.mobile-list .event-list li .text {
      margin-top: -3px;
      padding-top: 0px;
    }

    .status-mobile {
      i.event-icon::before {
        display: none;
      }
    }

    .event-color {
      width: 8px;
      height: 8px;
      display: inline-block;
      padding: 0px;
      margin-right: 5px;
      border-radius: 100%;
      margin-left: -31px;

      &.available,
      &.resolved_maintenance,
      &.canceled_maintenance,
      &.resolved,
      &.canceled {
        background-color: #2e9e77;
      }

      &.major {
        background-color: #d7373f;
      }

      &.minor {
        background-color: #e68618;
      }

      &.potential {
        background-color: grey;
      }

      &.maintenance {
        background-color: #2680eb;
      }
    }
  }
}
