.sub-row{
    border: 1px solid;
    font-size: 9px;
    padding:10px;
}
.sub-header{
border: 1px solid;
font-size: 10px;
}

.show-more-link{

    margin-top: 10px;
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 10px;
    font-family: 'Adobe Clean';
}
.confirmoptions-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
.restart-notification{
    text-align: left;
    // padding-left: 40px;
}
  


