.events-button {

      margin: 5px;
    border-radius: 5px;
    padding: 5px;
    border-width: 1px;
    border-color: #cb340f;
    background: rgb(212 199 196 / 20%);
    text-align: center;
    color: #585858;
    font-size: 12px;
   &:hover {
    background: #cb340f;
    color: white;
}
     

}
.show-more-link{

    margin-top: 10px;
    background: none;
    border: none;
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 10px;
    font-family: 'Adobe Clean';
}
.restart{
padding:10px !important;
}
.restart-workflow{
padding:10px !important;
}
.feedback{
padding: 10px !important;
}
